/* .container {
    display: flex;
    align-items: space-between;
    justify-content: space-around;
    width: 100%;
    height: 100%;
    border: 2px solid rgb(187, 0, 0);
} */

.container {
    /* font-size: medium; */
    /* border: 1px solid #b1b1b1; */
    border-radius: 5px;
}

.box {
    background-color: rgb(45, 197, 50);
    margin: 1px;
    font-size: 0.2em;
    flex-grow: 1;
    aspect-ratio: 1;
}

.box-row {
    background-color: rgb(255, 255, 255);
    display: flex;

}

.dir-label {
    background-color: rgb(255, 255, 255);
    margin: 1px;
    /* font-size: 1em; */
    color: black;
    flex-grow: 1;
}