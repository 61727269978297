.calculator-input {
    align-items: center;
    justify-content: space-between;
    padding: 5%;
    font-size: x-small;
    flex-grow: 1;
    width: 150px;
}

.calculator-input-container {
    width: 100%;
    border: 1px solid #b1b1b1;
    /* background-color: #b1b1b1; */
    background-color: #282c3433;
    color: rgb(0, 0, 0);

    border-radius: 5px;
    padding: 5px;
    /* display: flex; */
}