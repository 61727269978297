.calculator-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* margin: 20px; */
    padding: 20px;
    /* border: 1px solid #ccc; */
    /* border-radius: 5px; */
    /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3); */
    background-color: #ffffff;
    color: black;
}




/* .calculator-input-container {
    display: flex;
    width: 50%;
    align-items: center;
    justify-content: center;
    margin: 10px;
    font-size: x-small;
} */

.calculator-input label {
    margin-right: 10px;
}

.calculator-input input {
    width: 100px;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.calculator-input input:focus {
    outline: none;
    border-color: #0077ff;
    box-shadow: 0 0 5px rgba(0, 119, 255, 0.5);
}

.calculator-input input[type="number"]::-webkit-inner-spin-button,
.calculator-input input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.calculator-input input[type="number"] {
    -moz-appearance: textfield;
}